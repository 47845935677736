<!--
 * @Author       : hugo 1634765756@qq.com
 * @Date         : 2022-05-12 14:34:34
 * @LastEditors  : hugo
 * @LastEditTime : 2022-06-13 11:37:45
 * @FilePath     : /miaohang/src/components/Task/Main/LineBar/TaskTitle.vue
 * @Description  :
-->
<template>
  <div
    class="components_task_main_linebar_task_title_page bbox d-flex align-center"
  >
    <div
      v-if="showTitleIcon"
      class="icon flex-shrink-0"
    ></div>
    <status-tag
      v-if="showTitleStatusTag"
      class="mr-4"
    ></status-tag>
    <title-status-tag
      v-if="showTitleStatus"
      class="mr-5"
      :levelStatus="String(task.task.levelStatus)"
      :processStatus="String(task.task.processStatus)"
    ></title-status-tag>
    <input
      type="text"
      class="title"
      placeholder="输入任务标题"
      :readonly="!title_status"
      v-model="task_config.task_name"
    >
  </div>
</template>
<script>
import TitleStatusTag from '@/components/Task/Main/LineBar/TaskTitle/TitleStatusTag'
import StatusTag from './TaskTitle/StatusTag'
export default {
  components: {
    TitleStatusTag,
    StatusTag
  },
  props:{
    editable: {
      type: Boolean,
      default: true,
    }
  },
  inject: [
    'showTitleIcon',
    'showTitleStatus',
    'showTitleStatusTag',
  ],
  computed: {
    task () {
      return this.$store.state.task
    },
    task_config () {
      return this.$store.state.task_config
    },
    title_status() {
      const { editable } = this
      const { call } = this.task_config
      if (call) {
        return true
      }
      if (editable) {
        return true
      }
      return false
    }
  },
  mounted(){
    // // console.log(this.task);
    const { task,task_config } = this
    if (task.task) {
      const { name } = task.task
      const { task_name } = task_config
      if (name && !task_name) {
        this.task_config.task_name = name
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.components_task_main_linebar_task_title_page{
  width: 100%;
  background-color: #fff;
  .icon{
    @include background;
    width:24px;
    height: 24px;
    background-image: url(~@/assets/images/task/task_title_icon.png);
    margin-right: 16px;
  }
  .title{
    @include placeholder(#acacac, bold);
    width: 100%;
    flex: 1;
    border: none;
    outline: none;
    font-size: 22px;
    font-weight: bold;
    color: #333;
  }
}
</style>
